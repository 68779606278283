import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <center>
          <p className="footer-text">
            DDO Audit is brought to you by Clemeit of Thelanis.
            <br />
            This utility is subject to change without notice.
          </p>
        </center>
      </div>
    );
  }
}
